import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API_DOMAIN } from "../lib/api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ReactImageFileToBase64 from "react-file-image-to-base64";

const EditPost = () => {
  const params = useParams();
  const { postId } = params;
  const client = localStorage.getItem("client");
  const email = localStorage.getItem("email");
  const [post, setPost] = useState(null);
  const history = useHistory(); // Para navegar programáticamente
  const [websites, setWebsites] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [fullCat, setFullCat] = useState(null);

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [sections, setSections] = useState([]);
  const [selectedWebsites, setSelectedWebsites] = useState([]);
  const [date, setDate] = useState(getFormattedDate());
  const [mainPhoto, setmainPhoto] = useState();
  const [website, setWebsite] = useState();
  const [stringWebsite, setStringWebsite] = useState("");
  const [categories, setCategories] = useState();
  const [loading, setLoading] = useState(false);

  function getFormattedDate() {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    const isoDate = date;
    const dateObj = new Date(isoDate);

    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getUTCDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    setDate(formattedDate);
  }, [date]);

  useEffect(() => {
    getWebsites();
  }, []);

  useEffect(() => {
    const selectedWebsite = websites.find((web) => web.name === stringWebsite);
    setWebsite(selectedWebsite);
  }, [stringWebsite]);

  const getWebsites = () => {
    if (email === "wolf@whyw8t.com") {
      const filteredWebsites = [    {
        "_id": "653ab604360fa0f97e991be7",
        "name": "mrgraywolf",
        "displayName": "Mr. Gray Wolf",
        "categories": [
            {
                "_id": "653bcf89360fa0f97e993436",
                "name": "Bailout"
            },
            {
                "_id": "653bcfa7360fa0f97e99343c",
                "name": "Commodities"
            },
            {
                "_id": "653bcfce360fa0f97e993443",
                "name": "COVID-19"
            },
            {
                "_id": "653bcfd7360fa0f97e993447",
                "name": "Crypto"
            },
            {
                "_id": "653bcfdd360fa0f97e99344b",
                "name": "Economics"
            },
            {
                "_id": "653bcfe2360fa0f97e99344f",
                "name": "Energy"
            },
            {
                "_id": "653bcfea360fa0f97e993453",
                "name": "Geopolitical"
            },
            {
                "_id": "653bcff0360fa0f97e993457",
                "name": "Markets"
            },
            {
                "_id": "653bcff6360fa0f97e99345b",
                "name": "Medical"
            },
            {
                "_id": "653bcffd360fa0f97e99345f",
                "name": "Military"
            },
            {
                "_id": "653bd007360fa0f97e993463",
                "name": "Personal-Finance"
            },
            {
                "_id": "653bd012360fa0f97e993467",
                "name": "Political"
            },
            {
                "_id": "653bd016360fa0f97e99346e",
                "name": "Technology"
            }
        ]
    }]
      setWebsites(filteredWebsites);
    } else {
    axios
      .get(`${API_DOMAIN}/websites`, {
        headers: { Authorization: `Bearer ${client}` },
      })
      .then((res) => {
        const websiteData = res.data;
        setWebsites(websiteData);

        // Busca el objeto website en el arreglo websiteData
        const selectedWebsite = websiteData.find((web) => web.name === website);

        if (selectedWebsite) {
          setWebsite(selectedWebsite);
        } else {
          // Si no se encontró un sitio web coincidente, puedes manejarlo aquí
          console.error("No se encontró un sitio web coincidente.");
        }
      })
      .catch((err) => {
        console.error("Error cargando websites:", err);
      });
  }};

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_DOMAIN}/post?id=${postId}`, {
        headers: { Authorization: `Bearer ${client}` },
      })
      .then((res) => {
        setPost(res.data[0]);
        setTitle(res.data[0].title);
        setAuthor(res.data[0].author);
        setSections(res.data[0].content);
        setCategory(res.data[0].category[0]);
        setSelectedCat(res.data[0].category[0]._id);
        setCategoryId(res.data[0].categoryId);
        setmainPhoto(res.data[0].mainPhoto);
        setStringWebsite(res.data[0].website);
        setDate(res.data[0].date);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching post:", error));
  }, [postId]);

  useEffect(() => {
    // setCategories(website.categories);
    // setSelectedCat(website.categories[0]._id);
    // setFullCat(website.categories[0]);
  }, [website]);

  const addSection = (e) => {
    e.preventDefault();
    setSections([
      ...sections,
      {
        template: "1",
        items: [
          {
            id: 1,
            type: "PAR",
            payload: "",
          },
          {
            id: 2,
            type: "IMG",
            payload: "",
            caption: "",
          },
        ],
      },
    ]);
  };

  const deleteSection = (index) => {
    const newSections = [...sections];
    newSections.splice(index, 1);
    setSections(newSections);
  };

  const updateSection = (sectionIndex, itemIndex, field, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].items[itemIndex][field] = value;
    setSections(updatedSections);
  };

  const handleImageChange = (base64String, sectionIndex) => {
    const newSections = JSON.parse(JSON.stringify(sections)); // Copia profunda

    if (newSections[sectionIndex]) {
      // Buscar si ya existe un elemento de tipo IMG con id 2
      const existingImgIndex = newSections[sectionIndex].items.findIndex(
        (item) => item.type === "IMG"
      );

      const imageItem = {
        id: 2, // el ID siempre es 2 para elementos IMG
        type: "IMG",
        payload: base64String ? base64String[0].base64_file : null,
        caption:
          existingImgIndex !== -1
            ? newSections[sectionIndex].items[existingImgIndex].caption
            : "", // Usa el caption existente si ya hay uno
      };

      if (existingImgIndex !== -1) {
        // Actualiza el elemento IMG existente
        newSections[sectionIndex].items[existingImgIndex] = imageItem;
      } else {
        // Añade un nuevo elemento IMG
        newSections[sectionIndex].items.push(imageItem);
      }

      setSections(newSections);
    }
  };

  const handleMainImageChange = async (base64String, sectionIndex) => {
    try {
      // Supongamos que isBase64Url es una función que verifica si una URL es de tipo base64
      if (base64String && isBase64Url(base64String[0].base64_file)) {
        const imageData = {
          type: "IMG",
          payload: base64String[0].base64_file,
          name: Date.now().toString(),
        };

        // Suponiendo que tienes una función para cargar la imagen al servidor
        const response = await axios.post(API_DOMAIN + "/upload", imageData, {
          headers: { Authorization: `Bearer ${client}` },
        });

        if (response.data.url) {
          const imageItem = {
            type: "IMG",
            payload: response.data.url, // Usamos la URL devuelta desde el servidor
          };
          setmainPhoto(imageItem);
        } else {
          console.error("No se recibió la URL del servidor.");
        }
      } else {
        console.error("El archivo de imagen no es válido.");
      }
    } catch (error) {
      console.error("Error al subir la imagen:", error);
    }
  };

  const handleCaptionChange = (caption, sectionIndex) => {
    const newSections = JSON.parse(JSON.stringify(sections)); // Copia profunda

    if (newSections[sectionIndex]) {
      const itemIndex = newSections[sectionIndex].items.findIndex(
        (item) => item.type === "IMG"
      );

      if (itemIndex !== -1) {
        newSections[sectionIndex].items[itemIndex].caption = caption;
        setSections(newSections);
      }
    }
  };

  const removeImage = (sectionIndex, itemIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].items[itemIndex].file = null;
    updatedSections[sectionIndex].items[itemIndex].payload = "";
    setSections(updatedSections);
  };

  const isBase64Url = (str) => {
    // Expresión regular para verificar si la cadena es una URL en formato Base64
    const base64UrlPattern = /^data:image\/[a-z]+;base64,/;
    return base64UrlPattern.test(str);
  };

  const uploadImages = async () => {
    // Upload main photo first if it's in base64 format
    if (mainPhoto && isBase64Url(mainPhoto.payload)) {
      const imageData = {
        type: "IMG",
        payload: mainPhoto.payload,
        name: Date.now().toString(),
      };

      try {
        const response = await axios.post(API_DOMAIN + "/upload", imageData, {
          headers: { Authorization: `Bearer ${client}` },
        });
        if (response.data.url) {
          setmainPhoto({
            ...mainPhoto,
            payload: response.data.url,
          });
        }
      } catch (error) {
        console.error("Error uploading main photo:", error);
      }
    }

    const uploadPromises = sections.map(async (section) => {
      const updatedItems = await Promise.all(
        section.items.map(async (item) => {
          if (item.type !== "IMG") {
            return item; // return the item as-is if it's not an image
          }

          try {
            if (isBase64Url(item.payload)) {
              const imageData = {
                type: "IMG",
                payload: item.payload,
                name: Date.now().toString(),
              };

              const response = await axios.post(
                API_DOMAIN + "/upload",
                imageData,
                { headers: { Authorization: `Bearer ${client}` } }
              );

              return {
                ...item,
                payload: response.data.url,
              };
            } else {
              return item; // return the item as-is if it's not a base64 image
            }
          } catch (error) {
            console.error("Error uploading image:", error);
            return item; // return the item as-is if an error occurs
          }
        })
      );

      return {
        ...section,
        items: updatedItems,
      };
    });

    const updatedSections = await Promise.all(uploadPromises);
    return updatedSections;
  };

  const addImageToSection = (sectionIndex) => {
    // Copia la sección específica
    const newSection = { ...sections[sectionIndex] };

    // Añade un nuevo objeto de tipo "IMG" a los items de la sección
    newSection.items.push({
      type: "IMG",
      payload: "", // Puedes inicializarlo vacío o con algún valor por defecto
      caption: "",
    });

    // Crea una copia completa del array de secciones
    const newSections = [...sections];

    // Reemplaza la sección antigua con la nueva
    newSections[sectionIndex] = newSection;

    // Actualiza el estado
    setSections(newSections);
  };

  const uploadMainPhoto = async () => {
    if (!mainPhoto || !isBase64Url(mainPhoto.payload)) {
      return null;
    }

    const imageData = {
      type: "IMG",
      payload: mainPhoto.payload,
      name: Date.now().toString(),
    };

    try {
      const response = await axios.post(API_DOMAIN + "/upload", imageData, {
        headers: { Authorization: `Bearer ${client}` },
      });
      if (response.data.url) {
        const newMainPhoto = {
          ...mainPhoto,
          payload: response.data.url,
        };
        setmainPhoto(newMainPhoto);
        return newMainPhoto;
      }
      return null;
    } catch (error) {
      console.error("Error uploading main photo:", error);
      return null;
    }
  };

  const updateArticle = async () => {
    console.log(website);
    console.log(categoryId);
    const dateObj = new Date(date);
    const isoDate = dateObj.toISOString();
    // Consolida todos los valores en un solo objeto.
    const values = {
      title,
      website: website.name,
      category,
      categoryId,
      date: isoDate,
      mainPhoto,
    };

    try {
      // Actualiza las imágenes y obtiene las nuevas secciones.
      const newMainPhoto = await uploadMainPhoto();
      const updatedSections = await uploadImages();
      // Agrega las secciones actualizadas al objeto values.
      const updatedValues = {
        ...values,
        content: updatedSections,
        mainPhoto: newMainPhoto ? newMainPhoto : mainPhoto, // Usa la nueva mainPhoto si está disponible
      };

      // Realiza la petición PATCH para actualizar el artículo.
      await axios.patch(`${API_DOMAIN}/post?id=${postId}`, updatedValues, {
        headers: { Authorization: `Bearer ${client}` },
      });

      // Notifica al usuario que el artículo fue modificado con éxito.
      toast.success("Articulo modificado!");

      const redirectTo = `/posts?website=${website.name}`;
      setTimeout(() => {
        history.push(redirectTo);
      }, 2000);
    } catch (error) {
      // Registra el error en la consola y notifica al usuario.
      console.error("Error updating article:", error);
      toast.error("Error al modificar el artículo");
    }
  };

  const CloneArticle = async () => {
    const dateObj = new Date(date);
    const isoDate = dateObj.toISOString();
    // Consolida todos los valores en un solo objeto.
    const values = {
      title,
      website: website.name,
      category,
      categoryId,
      date: isoDate,
      mainPhoto,
      active: true,
      published: false,
    };

    try {
      // Actualiza las imágenes y obtiene las nuevas secciones.
      const updatedSections = await uploadImages();

      // Agrega las secciones actualizadas al objeto values.
      values.content = updatedSections;

      // Realiza la petición PATCH para actualizar el artículo.
      await axios.post(
        `${API_DOMAIN}/newpost`,
        { data: values },
        {
          headers: { Authorization: `Bearer ${client}` },
        }
      );

      // Notifica al usuario que el artículo fue modificado con éxito.
      toast.success("Articulo clonado!");

      const redirectTo = `/posts?website=${website.name}`;
      setTimeout(() => {
        history.push(redirectTo);
      }, 2000);
    } catch (error) {
      // Registra el error en la consola y notifica al usuario.
      console.error("Error updating article:", error);
      toast.error("Error al modificar el artículo");
    }
  };

  const handleDelete = async () => {
    const userConfirmation = window.confirm(
      "¿Estás seguro de querer eliminar este artículo?"
    );

    if (userConfirmation) {
      try {
        await axios.patch(
          API_DOMAIN + "/post?id=" + postId,
          { active: false },
          {
            headers: { Authorization: `Bearer ${client}` },
          }
        );

        toast.success("Artículo eliminado!");
        const redirectTo = `/posts?website=${website.name}`;
        setTimeout(() => {
          history.push(redirectTo);
        }, 2000);
      } catch (error) {
        toast.error("Algo salió mal, intente nuevamente!");
      }
    } else {
      // El usuario hizo clic en "Cancelar", no hacer nada.
    }
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={true} />

      {(!loading && post) ? (
        <div
          style={{ textAlign: "center", maxWidth: "800px", margin: "0 auto" }}
        >
          <input
            type="text"
            placeholder="Article Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            required
            style={{
              marginBottom: "10px",
              padding: "5px",
              width: "100%",
              marginTop: "4rem",
            }}
          />
          <div style={{textAlign: "left" }}>
            <label>Website: </label>
            <br />
            <select
              onChange={(e) => {
                const selectedWebsiteId = e.target.value;
                const selectedWebsite = websites.find(
                  (web) => web._id === selectedWebsiteId
                );

                if (selectedWebsite) {
                  setCategory(selectedWebsite.categories[0]);
                  setCategoryId(selectedWebsite.categories[0]._id);
                  setWebsite(selectedWebsite);
                  setStringWebsite(selectedWebsite.name);
                  setCategories(website.categories);
                  setSelectedCat(website.categories[0]._id);
                  setFullCat(website.categories[0]);
                }
              }}
            >
              {websites.map((website) => {
                return (
                  <option
                  key={website._id}
                  value={website._id}
                  selected={website.name === post.website}
                >
                  {website.name}
                </option>
                );
              })}
            </select>
            <br />
            <label htmlFor="">Categoria / Category</label>
            <br />
            <select
              className="newPost-input"
              value={selectedCat}
              onChange={(e) => {
                const selectedCategoryId = e.target.value;
                const selectedCategory = website.categories.find(
                  (cat) => cat._id === selectedCategoryId
                );

                if (selectedCategory) {
                  setCategory(selectedCategory);
                  setCategoryId(selectedCategoryId);
                  setSelectedCat(selectedCategoryId);
                  setFullCat(selectedCategory);
                }
              }}
            >
              {website &&
                website.categories.map((cat) => (
                  <option key={cat._id} value={cat._id}>
                    {cat.name}
                  </option>
                ))}
            </select>
            <br />
            <label>Fecha:</label>
            <br />
            <input
              type="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <br />
          </div>

          <br />
          <label>Main Image </label>
<br />
          {mainPhoto && (
            <img
              style={{ height: "200px", width: "200px" }}
              src={mainPhoto.payload}
              alt=""
            />
          )}
          <br />
          <ReactImageFileToBase64
            style={{ marginBottom: "2rem" }}
            type={["png", "jpg", "jpeg"]} // los tipos de imagen admitidos
            imageRatio={1} // razón de aspecto 1:1
            onCompleted={(base64String) => handleMainImageChange(base64String)}
          />

          <button
            onClick={addSection}
            style={{
              marginBottom: "20px",
              padding: "10px 20px",
              fontSize: "16px",
              position: "fixed",
              bottom: "0px",
              right: "50px",
            }}
          >
            Add Section
          </button>
          {sections &&
            sections.map((section, sectionIndex) => (
              <div
                key={sectionIndex}
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#f1f1f1",
                  padding: "20px",
                  borderRadius: "5px",
                  marginTop: "3rem",
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    onClick={() => deleteSection(sectionIndex)}
                    style={{
                      padding: "8px 0px",
                      fontSize: "14px",
                      width: "20%",
                      backgroundColor: "red",
                      color: "white",
                      border: "0px solid",
                      borderRadius: "8px",
                      marginBottom: "1rem",
                    }}
                  >
                    Remove Section
                  </button>
                </div>

                {section.items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* Renderizar la imagen primero */}
                    <div>
                      {item.type === "IMG" && (
                        <div>
                          {item.payload && (
                            <img
                              src={item.payload}
                              style={{ height: "200px", width: "200px" }}
                              alt="Selected"
                            />
                          )}
                          <ReactImageFileToBase64
                            type={["png", "jpg", "jpeg"]} // los tipos de imagen admitidos
                            imageRatio={1} // razón de aspecto 1:1
                            onCompleted={(base64String) =>
                              handleImageChange(base64String, sectionIndex)
                            }
                          />
                          <input
                            type="text"
                            placeholder="Caption"
                            value={item.caption}
                            onChange={(e) =>
                              handleCaptionChange(e.target.value, sectionIndex)
                            }
                            style={{
                              marginBottom: "5px",
                              padding: "5px",
                              width: "100%",
                            }}
                          />
                          <button
                            onClick={() => removeImage(sectionIndex, itemIndex)}
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Remove Image
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Luego renderizar el CKEditor */}
                    <div>
                      {(item.type === "SUB" || item.type === "PAR") && (
                        <>
                          {item.type === "SUB" ? (
                            <div
                              style={{
                                textAlign: "left",
                                marginBottom: "1rem",
                              }}
                            >
                              Subheading:
                            </div>
                          ) : (
                            <div
                              style={{
                                textAlign: "left",
                                marginBottom: "1rem",
                              }}
                            >
                              Paragraph:
                            </div>
                          )}
                          <CKEditor
                            editor={ClassicEditor}
                            data={item.payload}
                            onChange={(event, editor) => {
                              const updatedData = editor.getData();
                              updateSection(
                                sectionIndex,
                                itemIndex,
                                "payload",
                                updatedData
                              );
                            }}
                            config={{
                              toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                                "insertTable",
                                "mediaEmbed",
                                "undo",
                                "redo",
                              ],
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ))}
                {!section.items.some((item) => item.type === "IMG") && (
                  <button onClick={() => addImageToSection(sectionIndex)}>
                    Add Image to Section
                  </button>
                )}
              </div>
            ))}
          <div style={{ marginBottom: "20px", textAlign: "left" }}></div>
          {post && (
            <>
              <button
                onClick={updateArticle}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                Update article
              </button>
              <br />
              <br />
              <button
                onClick={CloneArticle}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                Clone article
              </button>
              <br />
              <br />
              <button onClick={handleDelete}>Delete Article</button>
            </>
          )}
        </div>
      ) : (
        <>Loading Post...</>
      )}
    </>
  );
};

export default EditPost;
