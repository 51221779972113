import React, { useState, useEffect } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { API_DOMAIN } from "../lib/api";
import { Link, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const AllPosts = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(2);
  const [isAscending, setIsAscending] = useState(true);
  const [websites, setWebsites] = useState([]);
  const defaultWebsite = params.get("website") || "mrgraywolf"
  const [website, setWebsite] = useState(defaultWebsite);

  const client = localStorage.getItem("client");
  const email = localStorage.getItem("email");
  const style = {
    height: 100,
    border: "1px solid green",
    borderRadius: "8px",
    margin: 20,
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
  };

  const fetchMoreData = () => {
    axios
      .get(
        `${API_DOMAIN}/allposts?page=${currentPage}&items=20&website=${website}`,
        {
          headers: { Authorization: `Bearer ${client}` },
        }
      )
      .then((res) => {
        const newPosts = res.data;

        // Comprobar si la respuesta está vacía
        if (newPosts.length === 0) {
          setHasMore(false);
          return;
        }

        // Añadir nuevos posts al estado actual
        setItems((prevItems) => [...prevItems, ...newPosts]);

        // Incrementar el número de página para la próxima solicitud
        setCurrentPage((prevPage) => prevPage + 1);
      })
      .catch((err) => {
        console.error("Error cargando posts:", err);
        setHasMore(false);
      });
  };

  const handleDelete = (post) => {
    const userConfirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este artículo?"
    );

    if (userConfirmed) {
      axios
        .patch(
          `${API_DOMAIN}/post?id=${post._id}`,
          { active: false },
          {
            headers: { Authorization: `Bearer ${client}` },
          }
        )
        .then(() => {
          toast.success("article Deleted!");
          fetchData();
          // window.location.reload();
        })
        .catch((error) => console.error("Error deleting post:", error));
    }
  };

  const getWebsites = () => {
    if (email === "wolf@whyw8t.com") {
      const filteredWebsites = [
        {
        "_id": "653ab604360fa0f97e991be7",
        "name": "mrgraywolf",
        "displayName": "Mr. Gray Wolf",
        "categories": [
            {
                "_id": "653bcf89360fa0f97e993436",
                "name": "Bailout"
            },
            {
                "_id": "653bcfa7360fa0f97e99343c",
                "name": "Commodities"
            },
            {
                "_id": "653bcfce360fa0f97e993443",
                "name": "COVID-19"
            },
            {
                "_id": "653bcfd7360fa0f97e993447",
                "name": "Crypto"
            },
            {
                "_id": "653bcfdd360fa0f97e99344b",
                "name": "Economics"
            },
            {
                "_id": "653bcfe2360fa0f97e99344f",
                "name": "Energy"
            },
            {
                "_id": "653bcfea360fa0f97e993453",
                "name": "Geopolitical"
            },
            {
                "_id": "653bcff0360fa0f97e993457",
                "name": "Markets"
            },
            {
                "_id": "653bcff6360fa0f97e99345b",
                "name": "Medical"
            },
            {
                "_id": "653bcffd360fa0f97e99345f",
                "name": "Military"
            },
            {
                "_id": "653bd007360fa0f97e993463",
                "name": "Personal-Finance"
            },
            {
                "_id": "653bd012360fa0f97e993467",
                "name": "Political"
            },
            {
                "_id": "653bd016360fa0f97e99346e",
                "name": "Technology"
            }
        ]
    }
  ]
      setWebsites(filteredWebsites);
    } else {
    axios
      .get(`${API_DOMAIN}/websites`, {
        headers: { Authorization: `Bearer ${client}` },
      })
      .then((res) => {
        setWebsites(res.data);
      })
      .catch((err) => {
        console.error("Error cargando websites:", err);
      });
    }
  };

  const fetchData = () => {
    setItems([]);
    setCurrentPage(2);
    let defaultWeb = website;
    if (email === "wolf@whyw8t.com") {
      defaultWeb = 'mrgraywolf'
    }
    axios
      .get(`${API_DOMAIN}/allposts?page=1&items=20&website=${defaultWeb}`, {
        headers: { Authorization: `Bearer ${client}` },
      })
      .then((res) => {
        const newPosts = res.data;
        setItems(newPosts);
      })
      .catch((err) => {
        console.error("Error cargando posts:", err);
      });
  };

  useEffect(() => {
    getWebsites();
    // Restablecer currentPage y items cuando cambie el website
    setItems([]);
    setCurrentPage(2);
    // Restablecer hasMore a true
    setHasMore(true);
    // Luego llamar a fetchMoreData para cargar los datos
    fetchData();
  }, [website]); // Dependencia para este useEffect

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={true} />
      <div>
        <label>Website: </label>

        <select value={website} onChange={(e) => setWebsite(e.target.value)}>
          {websites.map((website) => {
            return <option value={website.name}>{website.name}</option>;
          })}
        </select>
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        >
          {items.map((post, index) => (
            <div style={style} key={index}>
              <Link
                to={`/posts2/${post._id}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  cursor: "pointer",
                  maxWidth: "65%",
                }}
              >
                <div style={{ fontSize: "1.3rem" }}>{post.title}</div>
              </Link>

              <div>
                <Link
                  to={{
                    pathname: `/post/${post._id}/edit`,
                  }}
                >
                  <button
                    style={{
                      padding: "12px 25px",
                      background: "#f39c12",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Edit
                  </button>
                </Link>
                <button
                  style={{
                    padding: "12px 25px",
                    background: "#e74c3c",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                  onClick={() => {
                    handleDelete(post);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </InfiniteScroll>
        {!hasMore && <>No more post left</>}
      </div>
    </>
  );
};

export default AllPosts;
