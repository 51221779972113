import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import classes from "./MainNavigation.module.css";
import logoutButton from "../../assets/power-button.png";
import { useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { loginsuccess, logout } from '../../store/actions/authActions';

const MainNavigation = () => {

  const dispatch = useDispatch();

  const loggedIn = useSelector(state => state.auth.loggedIn);

  const history = useHistory();

  
  const handleLogout = () => {
    dispatch(logout());
    history.push('/login');
  };


  return (
    <header className={classes.header}>
      <div className={classes.logo}>Blogs Admin</div>
      {loggedIn && (
        <nav className={classes.nav}>
          <ul>
            <li>
              <NavLink
                to="/posts?project=descubritodo"
                activeClassName={classes.active}
              >
                Posts
              </NavLink>
            </li>
            <li>
              <NavLink to="/new-post" activeClassName={classes.active}>
                Crear post
              </NavLink>
            </li>
            <li>
            <button
                className={classes.logoutButton} // Apply your custom CSS class here
                onClick={() => handleLogout()}
              >
                <img
                  src={logoutButton}
                  alt="Log out"
                  className={classes.logoutIcon} // Apply your custom CSS class here
                />
                Log Out
              </button>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default MainNavigation;
