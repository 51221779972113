export const API_DOMAIN = "https://api.descubritodo.com";
// export const API_DOMAIN = "http://localhost:5008";

export async function getAllCategories() {
  const client = localStorage.getItem("client");
  const response = await fetch(`${API_DOMAIN}/categories`, {
    headers: new Headers({ Authorization: `Bearer ${client}` }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch categories.");
  }

  const transformedCategories = [];

  for (const key in data) {
    const catObj = {
      id: key,
      ...data[key],
    };

    transformedCategories.push(catObj);
  }

  return transformedCategories;
}

export async function uploadMediaToBucket(media) {
  const client = localStorage.getItem("client");
  const response = await fetch(`${API_DOMAIN}/upload`, {
    method: "POST",
    body: JSON.stringify(media),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${client}`,
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not create post.");
  }

  return null;
}

export async function getSinglePost(postId) {
  const client = localStorage.getItem("client");
  const response = await fetch(`${API_DOMAIN}/post?id=${postId}`, {
    headers: new Headers({ Authorization: `Bearer ${client}` }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch post.");
  }

  const loadedPost = {
    id: postId,
    ...data,
  };
  return loadedPost;
}

export async function addPost(postData) {
  const client = localStorage.getItem("client");
  const response = await fetch(`${API_DOMAIN}/newpost`, {
    method: "POST",
    body: JSON.stringify(postData),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${client}`,
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not create post.");
  }

  return null;
}

export async function updatePost(postData) {
  const client = localStorage.getItem("client");
  const response = await fetch(`${API_DOMAIN}/post`, {
    method: "PATCH",
    body: JSON.stringify(postData),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${client}` 
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not create post.");
  }

  return null;
}

// export async function addComment(requestData) {
//   const response = await fetch(`${API_DOMAIN}/comments/${requestData.postId}.json`, {
//     method: 'POST',
//     body: JSON.stringify(requestData.commentData),
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
//   const data = await response.json();

//   if (!response.ok) {
//     throw new Error(data.message || 'Could not add comment.');
//   }

//   return { commentId: data.name };
// }

// export async function getAllComments(postId) {
//   const response = await fetch(`${API_DOMAIN}/comments/${postId}.json`);

//   const data = await response.json();

//   if (!response.ok) {
//     throw new Error(data.message || 'Could not get comments.');
//   }

//   const transformedComments = [];

//   for (const key in data) {
//     const commentObj = {
//       id: key,
//       ...data[key],
//     };

//     transformedComments.push(commentObj);
//   }

//   return transformedComments;
// }
