// store/reducers/authReducer.js
import { LOGIN_SUCCESS, LOGOUT } from '../actions/authActions';

const initialState = {
  loggedIn: false,
  client: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        client: action.payload.accessToken,
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        client: null,
      };
    default:
      return state;
  }
};

export default authReducer;
