import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { API_DOMAIN } from "../lib/api";
import axios from "axios";
import "../styles/Preview.scss";
import parse from "html-react-parser";
import toast, { Toaster } from "react-hot-toast";

const PostDetail2 = () => {
  const params = useParams();
  const { postId } = params;
  const history = useHistory();
  const client = localStorage.getItem("client");
  const [post, setPost] = useState(null);
  const [showBtns, setShowBtns] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_DOMAIN}/post?id=${postId}`, {
        headers: { Authorization: `Bearer ${client}` },
      })
      .then((res) => setPost(res.data[0]))
      .catch((error) => console.error("Error fetching post:", error));
  }, [postId]);

  const handleDelete = () => {
    const userConfirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este artículo?"
    );

    if (userConfirmed) {
      axios
        .patch(
          `${API_DOMAIN}/post?id=${postId}`,
          { active: false },
          {
            headers: { Authorization: `Bearer ${client}` },
          }
        )
        .then(() => {
          toast.success("article deleted");
          setTimeout(() => {
            history.push(`/posts?website=${post.website}`);
          }, 2000);
        })
        .catch((error) => console.error("Error deleting post:", error));
    }
  };

  const handlePublish = () => {
    axios.patch(
      `${API_DOMAIN}/post?id=${post._id}`,
      {
        published: true,
      },
      {
        headers: { Authorization: `Bearer ${client}` },
      }
    );
  };

  const handleDraft = () => {
    axios.patch(
      `${API_DOMAIN}/post?id=${post._id}`,
      {
        published: false,
      },
      {
        headers: { Authorization: `Bearer ${client}` },
      }
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
  };

  const renderParts = (part) => {
    // console.log(part)
    switch (part.type) {
      case "SUB":
        if (part.payload != "") {
          return (
            <>
              <h2 className="post-subtitle">{part.payload}</h2> <br />
            </>
          );
        }

        break;
      case "PAR":
        return <p className="post-parr">{parse(part.payload)}</p>;
        break;
      case "IMG":
        if (part.payload != "") {
          return (
            <>
              <div className="slide-image">
                <img
                  className="post-image"
                  effect="blur"
                  src={part.payload} // use normal <img> attributes as props
                  placeholderSrc={part.payload}
                />

                <figcaption
                  className="article-caption"
                  style={{ textAlign: "left" }}
                >
                  {part.caption}
                </figcaption>
              </div>
            </>
          );
        }

        break;
    }
  };

  const renderSection = (sec) => {
    switch (sec.template) {
      case "1":
        return (
          <div className="template-2">
            {sec.items.map((part) => {
              return renderParts(part);
            })}
          </div>
        );
        break;
      case "2":
        return (
          <div className="template-2">
            {sec.items.map((part) => {
              return renderParts(part);
            })}
          </div>
        );
        break;
      case "3":
        return (
          <div className="template-3">
            {sec.items.map((part) => {
              return renderParts(part);
            })}
          </div>
        );
        break;
    }
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={true} />
      <div
        style={{
          width: "99.1vw",
          left: "0px",
          position: "absolute",
          paddingBottom: "3rem",
        }}
      >
        {/* Renderizar el contenido del post aquí, haciendo que los campos sean editables si isEditable es verdadero */}

        {/* 5sites article preview */}
        <div className="article-preview-5sites">
          {post ? (
            <>
              {/* Ícono de configuración para controlar la visibilidad del div */}
              <span
                onClick={() => setShowBtns(!showBtns)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#008080",
                  color: "white",
                  padding: "10px 10px",
                  borderRadius: "6px",
                }}
              >
                <i
                  className="fas fa-cog"
                  style={{ width: "20px", height: "30px" }}
                ></i>{" "}
                <span>Settings</span>
              </span>

              {/* Mostrar el div condicionalmente basado en el estado showBtns */}
              {showBtns && (
                <div
                  className="btns"
                  style={{
                    height: "fit-content",
                    marginTop: "0.5rem",
                    backgroundColor: "#9ecffa4d",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ marginBottom: "1rem" }}>
                    Current Category:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {post.category[0].name}
                    </span>{" "}
                    <br />
                    Date:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {formatDate(post.date)}
                    </span>{" "}
                    <br />
                    Website:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {post.website}
                    </span>{" "}
                    <br />
                    Project:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {post.project}
                    </span>{" "}
                    <br />
                    <br />
                    Status:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {post.published ? (
                        <span
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            padding: "3px",
                            borderRadius: "5px",
                          }}
                        >
                          Published
                        </span>
                      ) : (
                        <span
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            padding: "3px",
                            borderRadius: "5px",
                          }}
                        >
                          Draft
                        </span>
                      )}
                    </span>{" "}
                    <br />
                    <br />
                  </div>
                  <Link
                    to={{
                      pathname: `/post/${postId}/edit`,
                    }}
                  >
                    <button
                      style={{
                        padding: "8px 10px",
                        background: "#f39c12",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginRight: "10px",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </button>
                  </Link>
                  <button
                    style={{
                      padding: "8px 10px",
                      background: "#e74c3c",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={handleDelete}
                  >
                    Delete
                  </button>

                  {post.published ? (
                    <button
                      style={{
                        padding: "8px 10px",
                        background: "green",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={handleDraft}
                    >
                      Back to draft
                    </button>
                  ) : (
                    <button
                      style={{
                        padding: "8px 10px",
                        background: "green",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={handlePublish}
                    >
                      Publish Article
                    </button>
                  )}
                </div>
              )}
              <div className="post-container">
                {/* headline deberia mostrar mainPhoto */}
                <div className="post-headline">
                  <h4
                    style={{
                      color: "#797979",
                      marginBottom: "0px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    {formatDate(post.date)}
                  </h4>
                  <h1 className="post-title"> {post.title}</h1>
                </div>
                <div className="show-sections">
                  {post &&
                    post.content.map((content) => {
                      return renderSection(content);
                    })}
                </div>
                {/* <div
                className="text-written"
                style={{ textAlign: "left", width: "100%", marginTop: "3rem" }}
              >
                <h4
                  style={{
                    textAlign: "left",
                    width: "100%",
                    margin: "0 auto",
                    fontWeight: "400",
                  }}
                >
                  {" "}
                  Written by
                </h4>
                <h1 className="post-title"> {post.author}</h1>
              </div> */}
              </div>
            </>
          ) : (
            <>Loading Post...</>
          )}
        </div>
      </div>
    </>
  );
};

export default PostDetail2;
