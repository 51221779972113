// store/actions/authActions.js
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';


// store/actions/authActions.js
export const loginSuccess = (accessToken) => {
    return {
      type: LOGIN_SUCCESS,
      payload: {
        accessToken,
      },
    };
  };
  
  export const logout = () => {
    return {
      type: LOGOUT,
    };
  };
  