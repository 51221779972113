import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AllPosts2 from "./pages/AllPosts2";
import PostDetail2 from "./pages/PostDetail2";
import EditPost from "./pages/EditPost";
import NewPost from "./pages/NewPost";
import NotFound from "./pages/NotFound";
import Layout from "./components/layout/Layout";
import { Login } from "./pages/Login";
import axios from "axios";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./store";
import { loginSuccess, logout } from "./store/actions/authActions";
import { PersistGate } from "redux-persist/integration/react";


function App() {
  // axios.defaults.baseURL = "http://localhost:5008"; //url entorno local
  axios.defaults.baseURL = "api.descubritodo.com"

  // const [loggedIn, setLoggedIn] = useState(false);
  let verifyLogin = localStorage.getItem("LoggedIn");
  // let client = localStorage.getItem("client");
  const history = useHistory();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Layout>
          <Switch>
            <Route path="/" exact>
              { !verifyLogin ? <Login /> : <Redirect to="/posts?project=descubritodo" />}
            </Route>
            <Route path="/posts" exact>
              {!verifyLogin ? <Login /> : <AllPosts2 />}
            </Route>
            <Route path="/posts">
              {!verifyLogin ? <Login /> : <AllPosts2 />}
            </Route>
            <Route path="/posts2/:postId">
              <PostDetail2 />
            </Route>
            <Route path="/post/:postId/edit" component={EditPost} />
            <Route path="/new-post">
              {!verifyLogin ? <Login /> : <NewPost />}
            </Route>
            <Route path="/login" exact>
              <Login />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </PersistGate>
    </Provider>
  );
}

export default App;
