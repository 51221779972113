import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Puedes usar 'sessionStorage' en lugar de 'localStorage' si lo prefieres
import rootReducer from "./reducers";

const persistConfig = {
  key: "root", // Puedes cambiar esto a tu preferencia
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(/* tus middlewares aquí */)
);

export const persistor = persistStore(store);

