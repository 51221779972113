import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { API_DOMAIN } from "../lib/api";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { loginSuccess } from "../store/actions/authActions";

export const Login = () => {
  const dispatch = useDispatch();

  const initState = {
    email: "",
    password: "",
    strategy: "local",
  };

  const history = useHistory();

  const [form, setForm] = useState(initState);

  const onSubmit = () => {
    form.email.length < 1 || form.password.length < 1
      ? toast.error("Please input your login info")
      : sendData();
  };

  const [loading, setLoading] = useState(false);

  const sendData = async () => {
    setLoading(true);
    await axios
      // eslint-disable-next-line
      .post(API_DOMAIN + "/users/login", {
        email: form["email"],
        website: "descubritodo",
        strategy: "local",
        password: form["password"],
      })
      .then((response) => {
        console.log("response", response);
        localStorage.setItem("LoggedIn", "true");
        localStorage.setItem("client", response.data.access_token);
        localStorage.setItem("email", form["email"]);

        // Dispatch the loginSuccess action with the access token
        dispatch(loginSuccess(response.data.access_token));

        setLoading(false);
        history.push("/posts?sort=desc&project=descubritodo");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Unsuccesfull login, please try again");
      });
  };

  return (
    <div className="login-container">
      <h2 className="login-title tc">Login:</h2>
      <form>
        <p className="login-label">E-mail:</p>
        <input
          className="login-input"
          style={{ height: "2rem", width: "100%" }}
          onChange={(event) => {
            setForm({ ...form, email: event.target.value });
          }}
          type="email"
          id="email"
          name="email"
          placeholder="john.doe@example.com"
          required
        ></input>
        <p className="login-label">Password:</p>
        <input
          className="login-input"
          style={{ height: "2rem", width: "100%" }}
          onChange={(event) => {
            setForm({ ...form, password: event.target.value });
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onSubmit();
            }
          }}
          type="password"
          placeholder="●●●●●●●●"
          required
        ></input>
        <br />
        <br />
        <button
          className="login-button btn pointer"
          onClick={() => onSubmit()}
          type="button"
        >
          Login
        </button>
      </form>
      {loading === true ? <code className="tc">Loading...</code> : null}
    </div>
  );
};
