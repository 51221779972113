import React, { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactImageFileToBase64 from "react-file-image-to-base64";
import axios from "axios";
import { API_DOMAIN } from "../lib/api";
import toast, { Toaster } from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";

const NewPost = () => {
  const mrgraywolf = 
  {
    _id: "653ab604360fa0f97e991be7",
    name: "mrgraywolf",
    displayName: "Mr. Gray Wolf",
    categories: [
      {
          _id: "656f62ac1f2ce35cb57cf9a6",
          name: "Financial Horizons"
      },
      {
          _id: "656f62b31f2ce35cb57cf9ad",
          name: "Before The Bell"
      },
      {
          _id: "656f62be1f2ce35cb57cf9b4",
          name: "Market Recap"
      }
  ]
  };
  const email = localStorage.getItem("email");
  const [website, setWebsite] = useState(mrgraywolf);
  const [websites, setWebsites] = useState([]);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [mainPhoto, setMainPhoto] = useState({ type: "IMG", payload: "" });
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [publishing, setPublishing] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [selectedCat, setSelectedCat] = useState("");
  const [fullCat, setFullCat] = useState(null);
  const [done, setDone] = useState(false);
  const formRef = useRef(null);
  const history = useHistory(); // Para navegar programáticamente
  const client = localStorage.getItem("client");

  useEffect(() => {
    getWebsites();
    if (email === "wolf@whyw8t.com") {
      setWebsite(mrgraywolf);
    } else {
      setWebsite({
        _id: "64f0dff92a3f98c1662bc7e9",
        name: "descubritodo",
        displayName: "Descubri Todo",
        categories: [
          {
            _id: "6303e6b6f8f0580fb77d80c5",
            name: "Vacaciones",
          },
          {
            _id: "6303e6c1f8f0580fb77d80c7",
            name: "Turismo Sustentable",
          },
          {
            _id: "6303e6c9f8f0580fb77d80c9",
            name: "Nuestras Experiencias",
          },
        ]
      });
    }
  }, []);

  const getWebsites = () => {
    if (email === "wolf@whyw8t.com") {
      const filteredWebsites = [mrgraywolf];
      setWebsites(filteredWebsites);
    } else {
    axios
      .get(`${API_DOMAIN}/websites`, {
        headers: { Authorization: `Bearer ${client}` },
      })
      .then((res) => {
        setWebsites(res.data);
      })
      .catch((err) => {
        console.error("Error cargando websites:", err);
      });
    }
  };

  const addSection = (e) => {
    e.preventDefault();
    setSections([...sections, { template: "1", items: [] }]);
  };

  const deleteSection = (index) => {
    const newSections = [...sections];
    newSections.splice(index, 1);
    setSections(newSections);
  };

  const handleCKEditorChange = (event, editor, index) => {
    const data = editor.getData();
    const newSections = [...sections];

    // Busca el índice del último elemento de tipo "PAR" en la sección actual
    const lastParagraphIndex = newSections[index].items.findIndex(
      (item) => item.type === "PAR"
    );

    if (lastParagraphIndex !== -1) {
      // Si se encuentra un elemento de tipo "PAR", actualiza su contenido
      newSections[index].items[lastParagraphIndex].payload = data;
    } else {
      // Si no se encuentra un elemento de tipo "PAR", agrega uno nuevo
      newSections[index].items.push({
        id: 1,
        type: "PAR",
        payload: data,
      });
    }

    setSections(newSections);
  };

  const handleMainPhotoChange = (base64String) => {
    setMainPhoto({ type: "IMG", payload: base64String[0].base64_file });
  };

  const handleImageChange = (base64String, index) => {
    const newSections = [...sections];

    if (newSections[index]) {
      const imageItem = {
        id: 2,
        type: "IMG",
        payload: base64String[0].base64_file,
        caption: "", // Inicializa la leyenda vacía
      };

      // Buscar el item con id 2 y actualizarlo, o añadir uno nuevo si no existe
      const itemIndex = newSections[index].items.findIndex(
        (item) => item.id === 2
      );
      if (itemIndex !== -1) {
        newSections[index].items[itemIndex] = imageItem;
      } else {
        newSections[index].items.push(imageItem);
      }

      setSections(newSections);
    }
  };

  const handleCaptionChange = (caption, index) => {
    const newSections = [...sections];

    if (newSections[index]) {
      const itemIndex = newSections[index].items.findIndex(
        (item) => item.id === 2
      );
      if (itemIndex !== -1) {
        newSections[index].items[itemIndex].caption = caption;
      } else {
        console.error("No image item to set the caption for.");
      }

      setSections(newSections);
    }
  };

  const handleDone = async (e) => {
    e.preventDefault(); // Evita el envío del formulario

    if (formRef.current && formRef.current.checkValidity() === false) {
      // Make sure to trigger the HTML5 validation UI.
      formRef.current.reportValidity();
      return;
    }
    if (mainPhoto.payload === "") {
      toast.error("Por favor seleccione una foto principal.");
      return;
    }

    // Añade un toast de "cargando" aquí
    toast("Estamos subiendo las imágenes, por favor espera.");

    setLoading(true);
    setDone(!done);
    const articleData = {
      author,
      website,
      title,
      mainPhoto,
      content: sections,
    };

    // Función para subir la imagen y obtener una URL
    const uploadImage = async (data) => {
      let url = "";
      const client = localStorage.getItem("client");
      await axios
        .post(
          API_DOMAIN + "/upload",
          {
            type: "IMG",
            payload: data,
            name: Date.now().toString(),
          },
          {
            headers: { Authorization: `Bearer ${client}` },
          }
        )
        .then((res) => {
          url = res.data.url;
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });

      return url;
    };

    // Procesa la imagen principal
    if (articleData.mainPhoto.payload) {
      const newUrl = await uploadImage(articleData.mainPhoto.payload);
      articleData.mainPhoto.payload = newUrl;
    }

    // Procesa las imágenes de las secciones
    for (const section of articleData.content) {
      for (const item of section.items) {
        if (item.type === "IMG" && item.payload) {
          const newUrl = await uploadImage(item.payload);
          item.payload = newUrl;
        }
      }
    }

    setLoading(false);
    toast.dismiss("loadingToast");

    // Una vez que todas las imágenes han sido procesadas, muestra los datos del artículo
    console.log(JSON.stringify(articleData, null, 2));
  };

  const publishArticle = async () => {
    setPublishing(true);
    const client = localStorage.getItem("client");

    try {
      const response = await axios.post(
        `${API_DOMAIN}/newpost`,
        {
          data: {
            author: author,
            website: website.name,
            title: title,
            mainPhoto: mainPhoto,
            content: sections,
            date: new Date().toISOString(),
            active: true,
            published: true,
            isMainPost: false,
            categoryId: selectedCat,
            category: fullCat,
          },
        },
        {
          headers: { Authorization: `Bearer ${client}` },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Artículo creado!");
      } else {
        toast.error("Hubo un problema, intente nuevamente!");
      }
    } catch (error) {
      toast.error("Hubo un problema, intente nuevamente!");
    }

    setPublishing(false);
  };

  const saveDraft = async (e) => {
    e.preventDefault();
    setSavingDraft(true);
    const client = localStorage.getItem("client");

    try {
      const response = await axios.post(
        `${API_DOMAIN}/newpost`,
        {
          data: {
            author: author,
            website: website.name,
            title: title,
            mainPhoto: mainPhoto,
            content: sections,
            date: new Date().toISOString(),
            active: true,
            published: false,
            isMainPost: false,
            categoryId: selectedCat,
            category: fullCat,
          },
        },
        {
          headers: { Authorization: `Bearer ${client}` },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Artículo creado!");
        const redirectTo = `/posts?website=${website.name}`
        setTimeout(() => {
          history.push(redirectTo);
        }, 2000);
      } else {
        toast.error("Hubo un problema, intente nuevamente!");
      }
    } catch (error) {
      toast.error("Hubo un problema, intente nuevamente!");
    }

    setSavingDraft(false);
  };

  useEffect(() => {
    setCategories(website.categories);
    setSelectedCat(website.categories[0]._id);
    setFullCat(website.categories[0]);
  }, [website]);

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={true} />
      <form ref={formRef} onSubmit={(e) => handleDone(e)}>
        <h1>Crear artículo</h1>

        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {/* Formulario inicial */}
          <label>Website: </label>

          <select
            onChange={(e) => {
              const selectedWebsiteId = e.target.value;
              const selectedWebsite = websites.find(
                (web) => web._id === selectedWebsiteId
              );

              if (selectedWebsite) {
                setWebsite(selectedWebsite);
                setCategories(website.categories);
                setSelectedCat(website.categories[0]._id);
                setFullCat(website.categories[0]);
              }
            }}
          >
            {websites.map((website) => {
              return (
                <option key={website._id} value={website._id}>
                  {website.name}
                </option>
              );
            })}
          </select>

          <input
            type="text"
            placeholder="Título"
            required
            disabled={done}
            onChange={(e) => setTitle(e.target.value)}
          />
          {/* <input
            type="text"
            placeholder="Autor"
            required
            disabled={done}
            onChange={(e) => setAuthor(e.target.value)}
          /> */}

          <label htmlFor="">Categoria / Category</label>

          <select
            disabled={done}
            className="newPost-input"
            onChange={(e) => {
              setSelectedCat(e.target.value);
              categories.map((cat) => {
                if (cat._id === e.target.value) {
                  setFullCat(cat);
                }
              });
            }}
          >
            {website &&
              categories.map((cat) => {
                return (
                  <option key={cat._id} value={cat._id}>
                    {cat.name}
                  </option>
                );
              })}
          </select>
          <br />

          {!done && (
            <ReactImageFileToBase64
              type={["png", "jpg", "jpeg"]} // los tipos de imagen admitidos
              imageRatio={1} // razón de aspecto 1:1
              onCompleted={(base64String) =>
                handleMainPhotoChange(base64String)
              }
            />
          )}

          {mainPhoto.payload !== "" && (
            <img
              style={{ width: "100px", height: "100px" }}
              src={mainPhoto.payload}
              alt=""
            />
          )}
        </div>

        <div style={{ marginTop: "2rem" }}>
          {sections.map((section, index) => (
            <div key={index}>
              <select style={{ marginBottom: "0.5rem" }} disabled={done}>
                <option value="1">Template 1</option>
                {/* Otros templates aquí */}
              </select>

              <div className="editor">
                <CKEditor
                  disabled={done}
                  editor={ClassicEditor}
                  onChange={(event, editor) =>
                    handleCKEditorChange(event, editor, index)
                  }
                ></CKEditor>
              </div>

              <br />
              <br />
              {!done && (
                <ReactImageFileToBase64
                  type={["png", "jpg", "jpeg"]} // los tipos de imagen admitidos
                  imageRatio={1} // razón de aspecto 1:1
                  onCompleted={(base64String) =>
                    handleImageChange(base64String, index)
                  }
                />
              )}

              {section.items != "" &&
                section.items.map((item) => {
                  if (item.type === "IMG") {
                    return (
                      <img
                        src={item.payload}
                        style={{ width: "100px", height: "100px" }}
                      ></img>
                    );
                  }
                })}

              {/* <img src={} alt="" /> */}

              <br />

              <input
                type="text"
                placeholder="Caption"
                disabled={done}
                onChange={(e) => handleCaptionChange(e.target.value, index)}
              />

              <button
                style={{ marginTop: "2rem" }}
                onClick={() => deleteSection(index)}
              >
                Eliminar sección
              </button>
            </div>
          ))}
        </div>

        <div className="btns" style={{ marginTop: "2rem" }}>
          {done ? (
            <>
              {publishing || loading ? (
                <button disabled>Publicando...</button>
              ) : (
                <button onClick={publishArticle}>Publicar</button>
              )}
              {savingDraft || loading ? (
                <button disabled>Guardando borrador...</button>
              ) : (
                <button
                  onClick={(e) => {
                    saveDraft(e);
                  }}
                >
                  Guardar Borrador
                </button>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <button disabled>
                  <span>Cargando...</span>
                </button>
              ) : (
                <>
                  <button onClick={(e) => addSection(e)}>
                    Agregar sección
                  </button>{" "}
                  <button type="submit">Listo</button>
                </>
              )}
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default NewPost;
